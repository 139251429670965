import { render, staticRenderFns } from "./alarmReport.vue?vue&type=template&id=35bf81c4&scoped=true&"
import script from "./alarmReport.vue?vue&type=script&lang=js&"
export * from "./alarmReport.vue?vue&type=script&lang=js&"
import style0 from "./alarmReport.vue?vue&type=style&index=0&id=35bf81c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35bf81c4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1551980-cci-50566653-917405/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35bf81c4')) {
      api.createRecord('35bf81c4', component.options)
    } else {
      api.reload('35bf81c4', component.options)
    }
    module.hot.accept("./alarmReport.vue?vue&type=template&id=35bf81c4&scoped=true&", function () {
      api.rerender('35bf81c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/monitor/alarmSetting/alarmReport.vue"
export default component.exports